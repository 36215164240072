



























import Vue from "vue";
import { Component } from "nuxt-property-decorator";
import { faTimes, faCreditCard } from "@fortawesome/free-solid-svg-icons";

@Component({
  name: "PaymentBlockerModal",
})
export default class PaymentBlockerModal extends Vue {
  get heading() {
    return this.$store.getters["payments/paymentBlocker"].heading;
  }

  get btn() {
    return this.$store.getters["payments/paymentBlocker"].btn;
  }

  get faTimes() {
    return faTimes;
  }

  get faCreditCard() {
    return faCreditCard;
  }

  onClose() {
    if (process.browser && window) {
      window.location.hash = "";
      this.$emit("close");
    }
  }
}
